import * as React from "react"
import { graphql } from "gatsby"
import { ItemComponent } from "../components/item"

const ItemPage = ({ data }) => {
  return <ItemComponent item={data.tictic.item} />
}

export const query = graphql`
  query GetItem($id: ID!) {
    tictic {
      item: getItem(itemId: $id) {
        itemId
        slug
        name
        nameLocal
        nameOthers
        description
        cardImage {
          thumbUrl
          cardUrl
          dynamicUrl
        }
        cardLocation
        tags {
          tagId
          name
        }
        locations {
          locationId
          nameDisplay
          name
          country
        }
        links {
          url
          type
        }
        mapLinks {
          label
          link
        }
        tags {
          tagId
          name
          displayOrder
        }
      }
    }
  }
`
export default ItemPage
