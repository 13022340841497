import Layout from '../layout';
import { rhythm } from '../../utils/typography'
import React from "react";
import { graphql } from "gatsby";
import { OpenInApp } from '../mobile';


export const itemFragment = graphql`
fragment itemFragment on TICTIC_Item {
    itemId
    name
    nameOthers
    slug
    description
    cardImage {
      thumbUrl
      cardUrl
      dynamicUrl
    }
    cardLocation
    locations {
      locationId
      name
      nameDisplay
      country
    }
    tags {
      tagId
      name
      displayOrder
    }
    links {
      linkId
      type
      url
    }
    mapLinks {
      label
      link
    }
  }
`

export const ItemComponent = ({ item }) => {
    const seo = {
        metaTitle: item.name,
        metaDescription: item.description,
        shareImage: item.thumbUrl,
        article: false,
    };

    let name = item.name ?? '';
    let nameLocal = item.nameLocal;
    let locations = item.locations?.map(l => [l.nameDisplay, l.country].join(', ')).join('\n') ?? '';
    let description = item.description ?? '';
    let imageUrl = item.cardImage?.cardUrl ?? 'https://via.placeholder.com/300x200';

    return (
        <Layout seo={seo}>
            <div style={{ margin: '85px 0 0 0', padding: '12px' }}>
                <article>
                    <header>
                        <div style={{ alignItems: "center" }}>
                            <img style={{
                                borderRadius: "3%", 
                                maxHeight: '400px', 
                                width: 'auto',
                                display: "block",
                                height: 'auto',
                                objectFit: 'cover',                        
                            }} src={imageUrl} alt={name}
                            />
                        </div>
                        <h3
                            style={{
                                marginTop: rhythm(0),
                                marginBottom: rhythm(1),
                            }}
                        >
                            {name}
                        </h3>
                    </header>
                </article>
                <div>
                    {(nameLocal) && <p>Local Name: {nameLocal}</p>}
                    <p>{locations}</p>
                    <p style={{ color: "grey" }}>{description}</p>
                </div>

                <br />
                <ItemLinks links={item.links} />
                <br />
                <ItemMapLinks links={item.mapLinks} />
                <br />
                <ItemTags tags={item.tags} />
                <br />
                <OpenInApp type='item' slug={item.slug} />
            </div>
        </Layout>
    );
};



const ItemTags = ({ tags }) => {
    if (tags && tags.length > 0) {
        return (
            <div>
                <h4>Tags</h4>
                {tags.map(({ tagId, name }) => (
                    <div style={{ display: "inline-block", margin: "0.5em 0.8em 0.8em 0em" }} key={tagId}>
                        <span style={{
                            padding: "0.6em 1em",
                            fontSize: "0.9em",
                            fontStyle: "normal",
                            whiteSpace: "nowrap",
                            borderRadius: "2em",
                            backgroundColor: "#ddd",
                        }}>{name}</span>
                    </div>

                ))}
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}


const ItemLinks = ({ links }) => {
    if (links && links.length > 0) {
        return (
            <div>
                <h4>Links</h4>
                {links.map(({ url, type }) => (
                    <div style={{ display: "inline-block", margin: "0.5em 0.8em 0.8em 0em" }} key={type + url}>
                        <a style={{ color: "black", fontSize: "0.9em", textDecoration: "none" }} href={url}>
                            <span style={{
                                padding: "0.6em 1em",
                                fontSize: "0.9em",
                                fontStyle: "normal",
                                whiteSpace: "nowrap",
                                borderRadius: "2em",
                                backgroundColor: "#ddd",
                            }}>
                                {type}
                            </span>
                        </a>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}


const ItemMapLinks = ({ links }) => {
    if (links && links.length > 0) {
        return (
            <div>
                <h4>Map Links</h4>
                {links.map(({ link, label }) => (
                    <div style={{ display: "inline-block", margin: "0.5em 0.8em 0.8em 0em" }} key={label + link}>
                        <a style={{ color: "black", fontSize: "0.9em", textDecoration: "none" }} href={link}>
                            <span style={{
                                padding: "0.6em 1em",
                                fontSize: "0.9em",
                                fontStyle: "normal",
                                whiteSpace: "nowrap",
                                borderRadius: "2em",
                                backgroundColor: "#ddd",
                            }}>
                                {label}
                            </span>
                        </a>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}